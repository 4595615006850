import up from "../../../../assets/new_images/Up.svg";
import React, {useContext} from "react";
import translate from "../../../../i18n/translate";
import SingleElt from "./SingleElt";
import AlbumsContext from "../../../../datamanager/contexts/albums";
import AlbumElt from "./AlbumElt";
import NoContent from "../../../../components/NoContent";

export default function AlbumEltList() {

  const { albums } = useContext(AlbumsContext);

  console.log("================= album ", albums)

  if ( albums.count > 0 ){
    return (
      <div>
        <div className="topfan-titles">
          <span className="topfan-titlee">{translate('title')}</span>
          <span className="topfan-titlee">{translate('listenning')}</span>
          <span className="topfan-titlee">{translate('apparition')}</span>
          <span className="topfan-titlee">{translate('ordering')}</span>
        </div>

        {albums.results.map((album, index) => (
          <AlbumElt
            key={index}
            album={album}
            evolution={"up"}
            index={index}
          />
        ))}
      </div>
    );
  }else{
    return (<NoContent path={"/app/publish/album"} contentType={"Album"} />);
  }
}