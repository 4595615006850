import {useSwitchUnderlinedContext} from "../../datamanager/contexts/locals/switchUnderlined";

export default function SwitchUnderlinedPost({ values }) {

  const { selected, handleSwitchChange } = useSwitchUnderlinedContext();

  return (
    <div className="topfan-titleee">
      {
        values.map((value, index) => (
          <span key={index} className={selected === index + 1 ? "topfan-title2" : `topfan-title`}
                onClick={() => {
                  // selected === index + 1 ? "topfan-title" : `topfan-title2`
                  // handleSwitchChange(index + 1)
                }}>{value}</span>))
      }
    </div>
  );
}