import style from "../styles/components/MediaPostSwitch.module.css";
import {switchMediaPost} from "../../../../utils/Constants";
import {useSwitchUnderlinedContext} from "../../../../datamanager/contexts/locals/switchUnderlined";
import Post from "../components/Post";
import Media from "../components/Media"
import SwitchSliderPost from "../../../../components/SwitchSlidderPost";

export default function MediaPostSwitch({}){

  const {selected} = useSwitchUnderlinedContext();

  return (
    <div className={style.mediapostswitchcontainer}>
      <SwitchSliderPost switchValues={switchMediaPost} />
      {selected === 2 && <Media />}
      {selected === 1 && <Post />}
    </div>
  );
}