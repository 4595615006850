import React, { useContext, useState } from 'react'

import music from '../../assets/icons/pictureInPicture.svg';
import publication from '../../assets/icons/clock.svg';
import chart from '../../assets/icons/chartLine.svg';
import rev from '../../assets/icons/coin.svg';
import head from '../../assets/icons/headset.svg';
import param from '../../assets/icons/pokerChip.svg';

import bell from '../../assets/icons/bell.svg';
import dashboard from '../../assets/icons/dashboard.svg';

import download from '../../assets/icons/download.svg';
import back from '../../assets/icons/ArrowLeft.svg'
import disconnect from '../../assets/icons/disconnect.svg'

import { transformUrl } from '../../utils/functions';
import CurrentUserContext from '../../datamanager/contexts/currentUser';
import SideBarProfile from '../SideBarComponents/SideBarProfile';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import translate from '../../i18n/translate';

const DashTopNav = () => {
    const [open, setOpen] = useState(false);
    const { currentUser } = useContext(CurrentUserContext);

    const menuPrincipal = [
        {
            id: 1,
            icon: dashboard,
            label: translate("dash"),
            link: "/app/dashboard",
        },
        {
            id: 2,
            icon: music,
            label: translate("yourmusic"),
            link: "/app/audio",
        },
        {
            id: 3,
            icon: publication,
            label: translate("publication"),
            link: "/app/posts",
        },
        {
            id: 4,
            icon: chart,
            label: translate("marketing"),
            link: "/app/dashboardMarketing",
        },
        {
            id: 5,
            icon: rev,
            label: translate("incomes"),
            link: "/",
        },
    ]

    const configuration = [
        {
            id: 1,
            icon: param,
            label: 'Paramètres',
            link: "/",
        },
        {
            id: 2,
            icon: head,
            label: 'Obtenir de l’aide',
            link: "/",
        },
    ]

    // alert(window.location.pathname)
    const handleOpen = () => {
        gsap.fromTo(
            "#mob-nav",
            {
                x: '-100%',
            },
            {
                x: '0',
                duration: 0.75,
            }
        );
        setOpen(!open)
    }

    return (
        <>

            <nav className="mob-top-bar flex justify-between bg-black shadow-none p-6 h-auto w-full">
                <img alt="cover" className="userprofile" src={transformUrl(currentUser.cover, "sml")} onClick={handleOpen} />
                <div className="flex ">
                    <div className="relative h-full p-4 rounded-full mx-5 flex justify-center items-center" style={{ backgroundColor: "#161616" }}>
                        <img alt="logo" className="max-w-none" src={bell} style={{ width: '40px', maxWidth: 'none' }} />
                        <span className='absolute top-0 right-0 rounded-full text-white font-medium text-[8px] leading-3 p-2' style={{backgroundColor:'#FF5620'}}> 9+</span>
                    </div>

                    <Link to={"/app/publish/select-content-type"}>
                    <img alt="logo" className="logo h-full w-16" src={download} />
                    </Link>
                </div>
            </nav>


            <nav id="mob-nav" className='mobile-nav z-10 flex flex-col justify-start items-start gap-5 w-full h-full fixed top-0 left-0' style={open ? { display: 'flex' } : { display: 'none' }}>
                <div className="flex w-full justify-between pr-8">
                    <SideBarProfile />
                    <img src={back} alt="back icon" onClick={handleOpen} style={{ rotate: '180deg' }} />
                </div>
                <div className="menu-principal w-full px-8 flex flex-col mt-5">
                    <h3 className="font-medium text-[10px] leading-[13.5px] uppercase" style={{ color: 'rgba(255, 255, 255, 0.32)', letterSpacing: '7.5px' }}>Menu principal</h3>
                    <div className="">

                        {/* <Link to={'/app/dashboard'} className="menu-item flex px-3 pt-3 gap-4 items-center">
                            <img src={dashboard} alt="menu icon" className="active-dash-link" />
                            <span className="font-bold text-[14px] leading-[18.9px] active-dash-link">Tableau de bord</span>
                        </Link> */}
                        {
                            menuPrincipal.map((item) => (
                                <Link key={item.id} to={item.link} className=" flex px-3 pt-1 gap-4 items-center">
                                    <img src={item.icon} alt="menu icon" className={`${window.location.pathname === item.link ? 'active-dash-link' : 'not-active-dash-link'}`} />
                                    <span className={`font-bold text-[14px] leading-[18.9px] ${window.location.pathname === item.link ? 'active-dash-link' : 'not-active-dash-link'}`}>{item.label}</span>
                                </Link>
                            ))
                        }
                    </div>
                </div>
                <div className="configuration w-full px-8 pt-2 flex flex-col">
                    <h3 className="font-medium text-[10px] leading-[13.5px] uppercase" style={{ color: 'rgba(255, 255, 255, 0.32)', letterSpacing: '7.5px' }}>Configuration</h3>
                    <div>
                        {
                            configuration.map((item) => (
                                <Link to={item.link} className="menu-item flex px-3 pt-1 gap-4 items-center">
                                    <img src={item.icon} alt="menu icon" />
                                    <span className="font-bold text-[14px] leading-[18.9px]" style={{ color: '#808080' }}>{item.label}</span>
                                </Link>
                            ))
                        }
                    </div>
                </div>

                <aside className="w-full flex absolute bottom-0 left-0" style={{ backgroundColor: 'rgba(255, 0, 0, 0.12)', padding: '10px 25px' }}>
                    <img src={disconnect} alt="close icon" onClick={() => { }} />
                    <span className="font-bold text-[14px] leading-[18.9px] ml-5" style={{ color: '#FF0000' }}>Déconnexion</span>
                </aside>
            </nav>
        </>
    )
}

export default DashTopNav