import React from "react";
import {useSwitchUnderlinedContext} from "../datamanager/contexts/locals/switchUnderlined";
import SwitchUnderlinedPost from "./baseComponents/SwitchUnderlinedPost";

export default function SwitchSliderPost ({ switchValues }){

  const {selected} = useSwitchUnderlinedContext();

  return (
    <>
      <div className="topfan-top">
        <SwitchUnderlinedPost values={switchValues}/>
      </div>
      <div className="divv2">
        <div className={selected === 1 ? "subdivvv22" : `subdivvv2`}></div> { /* selected === 1 ? "subdivvv2" : "subdivvv22" */ }
      </div>
    </>
  )
}