import translate from "../i18n/translate";
import DataCardList from "../pages/app/audio/component/DataCardList";
import SwitchUnderlined from "./baseComponents/SwitchUnderlined";
import Search from "./baseComponents/Search";
import Select from "./baseComponents/Select";
import React from "react";
import {useSwitchUnderlinedContext} from "../datamanager/contexts/locals/switchUnderlined";
import SingleEltList from "../pages/app/audio/component/SingleEltList";
import AlbumEltList from "../pages/app/audio/component/AlbumEltList";
import {switchTabs} from "../utils/Constants";
import SwitchSlider from "./SwitchSlider";

export default function YourData() {

  const {selected} = useSwitchUnderlinedContext();

  return (
    <div className="datas">
      <div className="main-side">
        <span className="main-side-title">{translate('yourdata')}</span>
        <DataCardList/>
        <div className="main-side-elt">
          <span className="main-side-title">{translate('your_recent_contents')}</span>
          <div className="topfan">
            <SwitchSlider switchValues={switchTabs} />
            {selected === 1 && <SingleEltList />}
            {selected === 2 && <AlbumEltList />}
          </div>
        </div>
      </div>
    </div>
  );
}