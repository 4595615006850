import style from "../styles/components/PostElt.module.css"
import { CommentOutlined, ThumbUpOutlined, PlayArrow } from "@mui/icons-material";
import CurrentUserContext from "../../../../datamanager/contexts/currentUser";
import Certif from "../../../../assets/new_images/CircleWavyWarning.svg"
import {useContext} from "react";
import {transformUrl} from "../../../../utils/functions";
import {usePlaying} from "../../../../datamanager/contexts/playing";

export default function PostMusic({ music, postLike, postComment, postType }){

  const { currentUser } = useContext(CurrentUserContext);
  const { playing, handleSetPlaying } = usePlaying();

  return (
    <div className={style.postEltContainer}>
      <div className={style.postEltContent}>
        <div className={style.postEltContentBackground}></div>
        <div className={style.postEltContentMusicCard}>
          <div className={style.postEltContentMusicCardLeft}>
            <div className={style.postEltContentMusicCardLeftCoverContainer}>
              {music && music.cover && <img alt={"cover"} src={transformUrl(music.cover, "sml")} className={style.postEltContentMusicCardLeftCoverImg}/>}
            </div>
            <div className={style.postEltContentMusicCardLeftInfos}>
              {music && music.title && <span className={style.postEltContentMusicCardLeftMusicTitle}>{music.title}</span>}
              <div className={style.postEltContentMusicCardLeftArtistInfo}>
                <span className={style.postEltContentMusicCardLeftArtistName}>{currentUser.alias}</span>
                <div className={style.postEltContentMusicCardLeftArtistCertifContainer}>
                  <img alt={"certif"} src={Certif} className={style.postEltContentMusicCardLeftArtistCertifIcon} />
                </div>
              </div>
            </div>
          </div>
          <div className={style.postEltContentMusicCardRight}>
            <div className={style.postEltContentMusicCardRightPlayContainer}>
              <PlayArrow className={style.postEltContentMusicCardRightPlayIcon} onClick={() =>
              {
                handleSetPlaying({
                  isGoing: playing ? !playing.isGoing : true,
                  single: music
                });
              }
              } />
            </div>
          </div>
        </div>
      </div>
      <div className={style.postEltInfos}>
        <div className={style.postEltInfo}>
          <ThumbUpOutlined className={style.postEltInfoIcon} />
          <span className={style.postEltInfoValue}>{postLike}</span>
        </div>
        <div className={style.postEltInfo}>
          <CommentOutlined className={style.postEltInfoIcon} />
          <span className={style.postEltInfoValue}>{postComment}</span>
        </div>
      </div>
      {postType && <div className={style.postEltType}>
        {postType}
      </div>}
    </div>
  );
}