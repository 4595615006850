import {useSwitchUnderlinedContext} from "../../datamanager/contexts/locals/switchUnderlined";

export default function SwitchUnderlined({ values }) {

  const { selected, handleSwitchChange } = useSwitchUnderlinedContext();

  return (
    <div className="topfan-titleee">
      {
        values.map((value, index) => (
          <span key={index} className={selected === index + 1 ? "topfan-title" : `topfan-title2`}
                onClick={() => handleSwitchChange(index + 1)}>{value}</span>))
      }
    </div>
  );
}