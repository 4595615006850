import translate from "../../../../i18n/translate";
import up from "../../../../assets/new_images/Up.svg";
import SalesChart from "./SalesChart";
import React from "react";
import {filterSelect, salesData} from "../../../../utils/Constants";
import Select from "../../../../components/baseComponents/Select";
import {useStats} from "../../../../datamanager/contexts/Stats";
import {formatNumber} from "../../../../utils/FormatValues";

export default function DataChart() {
  const { stat } = useStats();

  return (
    <div className="audi">
      <div className="topsing">
        <span className="topsingtitle">
          {translate('totallistenning')}
        </span>
        <span className="topsingval">
          {stat ? stat.nb_listenning ? formatNumber(stat.nb_listenning) : 0 : 0}
        </span>
        <span className="topsingtitle">
          <div className="audii">
            <div className="audi-l">
              <img alt={"up"} className="topsingvalimg" src={up}/>
              <span className="topsingvaldescp">+0% {translate('from')} Decembre 2023 </span>
            </div>
          </div>
        </span>
        <SalesChart salesData={salesData} display_y={true}/>
      </div>
    </div>
  );
}