import moment from "moment";
import { format1, format3 } from "../../../../utils/Constants";

export const InitialValues = {
  title: "",
  cover: null,
  src: null,
  description: "",
  date: moment().format(format3), // Initialize with current date
  nb_of_download: 0,
  can_sell: false,
  price: 0,
  pre_order: false,
  category: null,
  pre_order_end_date: moment().subtract(10, "days").format(format3), // Initialize with current date - 10 days
  artist: null,
  media_accessibility: null,
  area: "",
  publication_date: moment().add(10, "days").format(format3), // Initialize with current date + 10 days
};
