import translate from "../../../../i18n/translate";
import React from "react";
import LeftDataElt from "./LeftDataElt";
import {useStats} from "../../../../datamanager/contexts/Stats";
import {formatNumber} from "../../../../utils/FormatValues";

export default function LeftData() {

  const { stat } = useStats()

  return (
    <div className="inst-data-values">
      <LeftDataElt title={translate('listenning')} value={stat ? formatNumber(stat.nb_listenning) : "-"} />
      <LeftDataElt title={translate('purchases')} value={stat ? formatNumber(stat.total_album_sold) : "-"} />
      <LeftDataElt title={translate('revenue')} value={stat ? formatNumber(stat.total_album_sales) : "-"} />
    </div>
  );
}