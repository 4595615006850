import "../styles/App.css";
import "./styles/LeftBar.css"
import televerser from "../assets/new_images/televerser.svg";
import React, {useState} from "react";
import translate from "../i18n/translate";
import Notif from "./leftBarComponents/Notif";
import LanguageSelect from "./leftBarComponents/LanguageSelect";
import RoundedWhiteBlackButtonWithIcon from "./baseComponents/RoundedWhiteBlackButtonWithIcon";
import Notifications from "./leftBarComponents/Notifications";
import PlayingSong from "./leftBarComponents/PlayingSong";
import TermsAndContitions from "./leftBarComponents/TermsAndContitions";
import SubMenuLeftBar from "./leftBarComponents/SubMenuLeftBar";
import {useMenuSwitchContext} from "../datamanager/contexts/locals/menuSwitch";
export default function LeftBar({ }){

  const [isMoreOptionOpen, setIsMoreOptionOpen] = useState(false);

  const handleToggleMoreOption = () => {
    setIsMoreOptionOpen(prevState => !prevState);
  };


  const { currentMenu  } = useMenuSwitchContext();

  return (
    <div className="leftbar">
      <div className="leftbar-top">
        <LanguageSelect/>
        <RoundedWhiteBlackButtonWithIcon text={translate('televerser')} icon={televerser}/>
      </div>
      <Notifications/>
      <PlayingSong/>
      <SubMenuLeftBar subMenu={currentMenu} />
      <TermsAndContitions/>
    </div>
  );
}