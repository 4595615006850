import style from "../styles/components/PostElt.module.css"
import { CommentOutlined, ThumbUpOutlined, MusicNoteOutlined } from "@mui/icons-material";
import {transformUrl} from "../../../../utils/functions";

export default function PostImg({ postCover, postLike, postComment }){
  return (
    <div className={style.postEltContainer}>
      <img alt={"elt cover"} src={transformUrl(postCover, "sml")} className={style.postEltCover} />
      <div className={style.postEltInfos}>
        <div className={style.postEltInfo}>
          <ThumbUpOutlined className={style.postEltInfoIcon} />
          <span className={style.postEltInfoValue}>{postLike}</span>
        </div>
        <div className={style.postEltInfo}>
          <CommentOutlined className={style.postEltInfoIcon} />
          <span className={style.postEltInfoValue}>{postComment}</span>
        </div>
      </div>
    </div>
  );
}