import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/app/SignUp/Login";
import LogMusic from "./pages/app/SignUp/LogMusic";
import LogPodcast from "./pages/app/SignUp/LogPodcast";
import Dashboard from "./pages/app/dashboard/Dashboard";
import DashboardPodcast from "./pages/app/dashboardPodcast/Dashboard";
import DashboardLabel from "./pages/app/dashboardLabel/Dashboard";
import Publish from "./pages/app/publish/Publish";
import Publish2 from "./pages/app/publishPodcast/Publish";
import PublishEpisode from "./pages/app/publishPodcast/PublishEpisode";
import PublishPodcast from "./pages/app/publishPodcast/PublishPodcast";
import PublishAlbum from "./pages/app/publish/PublishAlbum";
import PublishAudio from "./pages/app/publish/PublishAudio";
import PublishVideo from "./pages/app/publish/PublishVideo";
import ConMusic from "./pages/app/SignIn/Conmusic";
import LogLabel from "./pages/app/SignUp/LogLabel";
import Audio from "./pages/app/audio/Audio";
import Video from "./pages/app/video/Video";
import Album from "./pages/app/albums/Album";
import Episode from './pages/app/episode/Episodes';
import Podcast from './pages/app/podcast/Podcast';
import Royalties from "./pages/royalties/Royalties";
import Royalties2 from "./pages/royalties/PodcastRoyalties";
import ProtectedRoute from "./components/ProtectedRoute";
import Layout from "./components/Layout";
import Notifications from "./pages/notifications/Notifications";
import Profile2 from "./pages/app/profile/Profile2";
import Pres from "./pages/presentation/Pres";
import Playlist from "./pages/app/playlists/Playlist";
import AlbumDetails from "./pages/app/albums/AlbumDetails";
import SingleDetails from "./pages/app/playlists/SingleDetails";
import CurrentUserProvider from "./datamanager/providers/CurrentUser";
import SinglesProvider from "./datamanager/providers/Singles";
import AlbumsProvider from "./datamanager/providers/Albums";
import VideosProvider from "./datamanager/providers/Video";
import ModalProvider from "./datamanager/providers/modalProvider"
import { Toaster } from "react-hot-toast";
import EpisodesProvider from "./datamanager/providers/Episodes";
import PodcastsProvider from "./datamanager/providers/Podcasts";
import PodcastDetails from "./pages/app/podcast/PodcastDetails";
import EpisodeDetails from "./pages/app/playlists/EpisodeDetails";
import AddArtist from "./pages/app/publishLabel/AddArtist";
import Cgu from "./pages/cgu";
import Privacy from "./pages/privacy";
import ArtistDetails from "./pages/app/artist/ArtistDetails";
import { I18nPropvider } from "./i18n";
import useUser from "./hooks/useUser";
import ContentTypeSelect from "./pages/app/publish/ContentTypeSelect";
import { StoreDataProvider } from "./datamanager/contexts/StoreDatas";
import { PlayingProvider } from "./datamanager/contexts/playing";
import { StatsProvider } from "./datamanager/contexts/Stats";
import { AlbumCategoriesProvider } from "./datamanager/contexts/locals/albumCategory";
import { CurrentPageProvider } from "./datamanager/contexts/locals/publishAudio/currentPage";
import { BackErrProvider } from "./datamanager/contexts/locals/publishAudio/backErr";
import { SelectedAudioProvider } from "./datamanager/contexts/locals/publishAudio/selectedAudio";
import { SelectedImageProvider } from "./datamanager/contexts/locals/publishAudio/selectedImage";
import { MetaDataProvider } from "./datamanager/contexts/locals/publishAudio/metaData";
import { FinishedUploadProvider } from "./datamanager/contexts/locals/publishAudio/finishedUpload";
import { UploadPercentageProvider } from "./datamanager/contexts/locals/publishAudio/uploadPercent";
import { UploadSpeedProvider } from "./datamanager/contexts/locals/publishAudio/uploadSpeed";
import { MsgProvider } from "./datamanager/contexts/locals/publishAudio/msg";
import { ConnectedProvider } from "./datamanager/contexts/locals/publishAudio/connected";
import { SubmittingProvider } from "./datamanager/contexts/locals/publishAudio/isSubmitting";
import { UploadFileTypeProvider } from "./datamanager/contexts/FileTypeUpload";
import { useLanguage } from "./datamanager/contexts/Language";
import { LoadingProvider } from "./datamanager/contexts/locals/Dashboard/loading";
import CommingSoon from "./pages/app/publish/components/CommingSoon";
import { UploadedFilesProvider } from "./datamanager/contexts/locals/publishAlbum/uploadedFiles";
import Posts from "./pages/app/posts/posts";
import { PostProvider } from "./datamanager/contexts/posts";
import { CommentsProvider } from "./datamanager/contexts/comments";
import { MenuSwitchProvider } from "./datamanager/contexts/locals/menuSwitch";
import Home from "./pages/pages/Home";
import {Layout as ClientLayout} from './containers/Layout/Layout';
import Marketing from "./pages/pages/dashboard/Marketing";
function App() {
  const { language } = useLanguage();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useUser("home")

  const [cguAccepted, setCguAccepted] = useState(false);

  return (
    <CurrentUserProvider>
      <I18nPropvider locale={language}>
        <ModalProvider>
          <SinglesProvider>
            <AlbumsProvider>
              <VideosProvider>
                <EpisodesProvider>
                  <PodcastsProvider>
                    <StoreDataProvider>
                      <PlayingProvider>
                        <StatsProvider>
                          <SelectedAudioProvider>
                            <SelectedImageProvider>
                              <UploadFileTypeProvider>
                                <AlbumCategoriesProvider>
                                  <CurrentPageProvider>
                                    <BackErrProvider>
                                      <MetaDataProvider>
                                        <FinishedUploadProvider>
                                          <UploadPercentageProvider>
                                            <UploadedFilesProvider>
                                              <UploadSpeedProvider>
                                                <MsgProvider>
                                                  <ConnectedProvider>
                                                    <SubmittingProvider>
                                                      <LoadingProvider>
                                                        <PostProvider>
                                                          <CommentsProvider>
                                                            <MenuSwitchProvider>
                                                              <Routes>

                                                                <Route element={
                                                                  <ClientLayout />
                                                                } path="/">

                                                                  <Route index element={<Home />} />

                                                                </Route>

                                                                <Route path="/presentation" element={<Pres />}></Route>

                                                                <Route path="/start" element={<Login />}></Route>

                                                                <Route path="/start/music" element={<LogMusic cguAccepted={cguAccepted} />}></Route>

                                                                <Route path="privacy-policy" element={<Privacy />} />
                                                                <Route path="/cgu" element={<Cgu setCguAccepted={setCguAccepted} />}></Route>

                                                                <Route path="/start/podcast" element={<LogPodcast cguAccepted={cguAccepted} />}></Route>
                                                                <Route path="/start/label" element={<LogLabel cguAccepted={cguAccepted} />}></Route>
                                                                <Route path="/app/access" element={<ConMusic />}></Route>

                                                                <Route path={"/app/comming-soon"} element={<CommingSoon />}></Route>

                                                                <Route
                                                                  element={<ProtectedRoute auth={localStorage.getItem("access_token")} />}
                                                                >
                                                                  <Route element={
                                                                    <Layout />
                                                                  }>
                                                                  <Route
                                                                    path="/app/dashboard"
                                                                    element={
                                                                      <Dashboard />
                                                                    }
                                                                  ></Route>
                                                                  <Route
                                                                    path="/app/dashboardMarketing"
                                                                    element={
                                                                      <Marketing />
                                                                    }
                                                                  ></Route>

                                                                    <Route
                                                                      path="/app/dashboardPodcast"
                                                                      element={<DashboardPodcast />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/dashboardLabel"
                                                                      element={<DashboardLabel />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/label/artists"
                                                                      element={<AddArtist />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/audio"
                                                                      element={<Audio isActive={true} />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/episode"
                                                                      element={<Episode isActive={true} />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/video"
                                                                      element={<Video isActive={true} />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/albums"
                                                                      element={<Album isActive={true} />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/podcasts"
                                                                      element={<Podcast isActive={true} />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/albumdetails"
                                                                      element={<AlbumDetails />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/podcastdetails"
                                                                      element={<PodcastDetails />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/singledetails"
                                                                      element={<SingleDetails />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/artistdetails"
                                                                      element={<ArtistDetails />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/episodedetails"
                                                                      element={<EpisodeDetails />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/royalties"
                                                                      element={<Royalties isActive={true} />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/PodcastRoyalties"
                                                                      element={<Royalties2 isActive={true} />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/playlists"
                                                                      element={<Playlist isActive={true} />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/notifications"
                                                                      element={<Notifications isActive={true} />}
                                                                    ></Route>

                                                                    <Route
                                                                      path="/app/profile"
                                                                      element={<Profile2 isActive={true} />}
                                                                    ></Route>

                                                                    <Route
                                                                      path={"/app/posts"}
                                                                      element={<Posts />}
                                                                    ></Route>

                                                                  </Route>

                                                                  <Route path="/app/publish/select-content-type" element={<ContentTypeSelect />}></Route>

                                                                  <Route path="/app/publish" element={<Publish />}></Route>

                                                                  <Route
                                                                    path="/app/publish/album"
                                                                    element={
                                                                      <PublishAlbum />
                                                                    }
                                                                  ></Route>

                                                                  <Route
                                                                    path="/artists/add"
                                                                    element={<AddArtist />}
                                                                  ></Route>

                                                                  <Route
                                                                    path="/app/publish/video"
                                                                    element={<PublishVideo />}
                                                                  ></Route>

                                                                  <Route
                                                                    path="/app/publish/audio"
                                                                    element={
                                                                      <PublishAudio />
                                                                    }
                                                                  ></Route>

                                                                  <Route path="/app/publishPodcasts" element={<Publish2 />}></Route>

                                                                  <Route
                                                                    path="/app/publishPodcasts/podcast"
                                                                    element={<PublishPodcast />}
                                                                  ></Route>

                                                                  <Route
                                                                    path="/app/publishPodcasts/episode"
                                                                    element={<PublishEpisode />}
                                                                  ></Route>
                                                                </Route>
                                                              </Routes>
                                                            </MenuSwitchProvider>
                                                          </CommentsProvider>
                                                        </PostProvider>
                                                      </LoadingProvider>
                                                    </SubmittingProvider>
                                                  </ConnectedProvider>
                                                </MsgProvider>
                                              </UploadSpeedProvider>
                                            </UploadedFilesProvider>
                                          </UploadPercentageProvider>
                                        </FinishedUploadProvider>
                                      </MetaDataProvider>
                                    </BackErrProvider>
                                  </CurrentPageProvider>
                                </AlbumCategoriesProvider>
                              </UploadFileTypeProvider>
                            </SelectedImageProvider>
                          </SelectedAudioProvider>
                        </StatsProvider>
                      </PlayingProvider>
                    </StoreDataProvider>
                  </PodcastsProvider>
                </EpisodesProvider>
              </VideosProvider>
            </AlbumsProvider>
          </SinglesProvider>
        </ModalProvider>
        <Toaster />
      </I18nPropvider>
    </CurrentUserProvider>
  );
}

export default App;
