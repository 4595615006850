import React, {useContext} from "react";
import SinglesContext from "../../../../datamanager/contexts/singles";
import translate from "../../../../i18n/translate";
import SingleElt from "./SingleElt";
import { useLoadingContext } from "../../../../datamanager/contexts/locals/Dashboard/loading";
import { Skeleton } from "@mui/material";
import "../styles/single.module.css";
import MusicApi from "../../../../api/musics";
import { SpinnerCircularFixed } from "spinners-react";
import NoContent from "../../../../components/NoContent";

export default function SingleEltList() {

  const { singles, addSinglesAtEnd } = useContext(SinglesContext);
  const { isSingleFetching, setSingleFetching } = useLoadingContext();

  const handleFetchNext20Singles = async () => {
    const token = localStorage.getItem("access_token")

    const musicApi = new MusicApi(token)

    setSingleFetching(true);

    const response = await musicApi.getNext20Singles(singles.next)

    if (response.data) {
      console.log("===================next function call =======", singles.next);
      addSinglesAtEnd(response.data);
      setSingleFetching(false);
    }
  }

  console.log("+====single----===", singles);

  if( singles.count > 0 ){
    return (
      <div>
        <div className="topfan-titles">
          <span className="topfan-titlee">{translate('title')}</span>
          <span className="topfan-titlee">{translate('listenning')}</span>
          <span className="topfan-titlee">{translate('apparition')}</span>
          <span className="topfan-titlee">{translate('ordering')}</span>
        </div>

        {isSingleFetching ? <>
          <Skeleton className={"monit-main"} variant={"text"} width={"100%"} height={"2rem"} animation={"wave"} sx={{bgcolor: "#141414"}}/>
        </> : null}

        {singles && singles.results.map((single, index) => (
          <SingleElt
            key={index}
            single={single}
            evolution={"up"}
            index={index}
          />
        ))}

        {singles && singles.next &&
          <span onClick={() => {
            if(!isSingleFetching){
              handleFetchNext20Singles().then((res) => {
                console.log(res);
                console.log("===========sinles=====", singles)
              })
            }
          }} className="topfan-titles load-more ">
          <span className="load-more-title">load more</span>
            {isSingleFetching && <SpinnerCircularFixed
              size={30}
              thickness={100}
              speed={60}
              color="rgba(1, 0.337, 0.125, 1)"
              secondaryColor="rgba(255, 86, 32, 1)"
              className="justify-center"
            />}
        </span>
        }
      </div>
    );
  }else{
    return (<NoContent path={"/app/publish/audio"} contentType={"audio"} />);
  }
}