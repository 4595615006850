import React, { useContext, useEffect, useState } from "react";
import gsap from "gsap";
import SinglesContext from "../../../datamanager/contexts/singles";
import AlbumsContext from "../../../datamanager/contexts/albums";
import translate from "../../../i18n/translate";
import styles from "../../app/posts/styles/posts.module.css";
import "../../app/dashboard/styles/dashboard.module.css"
import Loader from "../../../components/Loader";
import NoContent from "../../../components/NoContent";
import { useLoadingContext } from "../../../datamanager/contexts/locals/Dashboard/loading";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";

import DashTopNav from "../../../components/DashTopNav/DashTopNav";
import GreedUser from "../../app/dashboard/components/GreedUser";
import DataChart from "../../app/dashboard/components/DataChart";
import DataCardList from "../../app/audio/component/DataCardList";
import SearchBar from "../../app/posts/components/SearcBar";


import '../../../styles/dashboard/marketing.scss'
import AdvitisementCard from "../../../components/Cards/AdvitisementCard/AdvitisementCard";
import SummaryCard from "../../../components/Cards/SummaryCard/SummaryCard";
import users from '../../../assets/icons/users.svg'
import LeftBarDatas5 from "../../../components/DashSideCards/LeftBarData5/LeftBarDatas5";

function Marketing() {
    const [open, setOpen] = useState(false);

    const { currentUser } = useContext(CurrentUserContext);
    // Get data from the global context
    const { singles } = useContext(SinglesContext);
    const { albums } = useContext(AlbumsContext);

    const { loading, handleSetLoading } = useLoadingContext();

    const handleOpen = () => {
        gsap.fromTo(
            "#mob-nav",
            {
                x: '-100%',
            },
            {
                x: '0',
                duration: 0.75,
            }
        );
        setOpen(!open)
    }

    // UseEffect section
    useEffect(() => {
        if (singles && albums) {
            handleSetLoading(false)
        }
    }, [singles, albums])


    return (
        <>
            {loading ? <Loader /> :
                albums.count || singles.count ? (
                    <div className="main-dash">
                        <DashTopNav />

                        <div className={`${styles.searchcontainer} mt-3`} >
                            <SearchBar searchPlaceholder={"Taper un mot clé à rechercher"} />
                        </div>
                        
                        <div className="datas">
                            <div className="main-side mt-28">
                                <h1 className="text-white font-bold text-4xl leading-10 text-start">Marketing</h1>

                                <div className="summaries mt-14">
                                    <p className="text-white font-normal text-2xl leading-9 text-start">Récapitulatif</p>

                                    <div className="summaries_container">
                                        <SummaryCard title={'Couverture'} image={users} stat={'6.01K'} direction={'up'} rating={'+3,778% ce mois'} />
                                        <SummaryCard title={'Clics sur le lien'} image={users} stat={'7.04K'}  />
                                        <SummaryCard title={'Lecture obtenue'} image={users} stat={'1.76K'} direction={'up'} rating={'+3,778% ce mois'} />
                                        <SummaryCard title={'Lecture obtenue'} image={users} comments={[{score:'340', comment:'Mentions J’aime'},{score:'522', comment:'Nouveaux abonnées'}]}/>
                                    </div>
                                </div>

                                <div className="mobile-left-bar-data-5">
                                    <LeftBarDatas5 />
                                </div>

                                <div className="advitisements mt-8">
                                    <span className="text-white font-normal text-2xl leading-9 text-start">Publicité récentes</span>

                                    <div className="advitisements_container">
                                        <AdvitisementCard title={'Rédemption'} subTitle={'Album'} status={'En cours'} date={'23 Fév. 2024'} />
                                        <AdvitisementCard title={'C’est comment'} subTitle={'1 piste  Rédemption'} status={'Terminé'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : <NoContent path={"/app/publish/select-content-type"} contentType={"album ou audio"} />}
        </>
    );
}

export default Marketing;
