import React from 'react'
import Button from '../../Button/Button'
import artist from '../../../assets/img/artist.png'
import done from '../../../assets/icons/done.svg'
import pending from '../../../assets/icons/pending.svg'
import './advitisement_card.scss'
const AdvitisementCard = ({ title, subTitle, status, date }) => {
    return (
        <div className="advitisement_card">
            <div className="top">
                <div className="artist">
                    <img src={artist} alt="artist thumb" />
                    <div>
                        <p>{title}</p>
                        <p>{subTitle}</p>
                    </div>
                </div>
                <p className="status">
                    {
                        status === 'En cours' ? <img src={pending} alt="pending" /> : <img src={done} alt="done" />
                    }
                    <span>{status}</span>
                    {status === 'En cours' && <span>Date de fin : <b>{date}</b></span>}
                </p>
            </div>
            <div className="middle">
                <p>Interaction sur le contenu</p>
                <aside>
                    <div>
                        <p>17480</p>
                        <p>Couverture total dans la plateforme</p>
                    </div>
                    <div>
                        <p>9720</p>
                        <p>Apparition dans les playlists</p>
                    </div>
                    <div>
                        <p>480</p>
                        <p>Ecoutes recoltées depuis le lien</p>
                    </div>
                    <div>
                        <p>3800FCFA</p>
                        <p>Dépense pour la promotion</p>
                    </div>
                </aside>
            </div>
            <div className="bottom">
                <Button text={'Dupliquer'} bg="rgba(128, 128, 128, 0.562)" />
                <Button text={'Promouvoir'} />
            </div>
        </div>
    )
}

export default AdvitisementCard