import style from "../styles/components/Post.module.css"
import PostImg from "./PostImg";
import Fond1 from "../../../../assets/new_images/back1.png"
import PostPlaylist from "./PostPlaylist";
import PostMusic from "./PostMusic";
import {usePost} from "../../../../datamanager/contexts/posts";
import {useLoadingContext} from "../../../../datamanager/contexts/locals/Dashboard/loading";
import {SpinnerInfinity} from "spinners-react";
import translate from "../../../../i18n/translate";

export default function Post({}){

  const { posts, postAlbumDetails } = usePost();
  const { isPostFetching } = useLoadingContext();

  // write a function to find in postAlbumDetails if the the postAlbumDetail matching the given index
  const findPostAlbumDetails = (index) => {
    const postAlbumDetailsIndex = postAlbumDetails.findIndex((postAlbumDetail) => postAlbumDetail.postIndex === index);
    if (postAlbumDetailsIndex !== -1) {
      return postAlbumDetails[postAlbumDetailsIndex];
    }
  }

  if( posts.count === 0 ){
    return <div className={style.noPost}>{translate("noPost")}</div>
  }else{
    return (
      <div className={style.postContainer}>
        {isPostFetching ?
          <SpinnerInfinity/>:
          posts && posts.results && posts.results.map((post, index) =>  {
              if (post.type === "IMAGE") {
                return <PostImg key={index} postCover={post.cover} postComment={post.comments.length} postLike={post.nb_of_likers} />
              }else if (post.type === "ALBUM") {
                return <PostPlaylist key={index} postCover={post.cover} postComment={post.comments.length} postLike={post.nb_of_likers} postCount={postAlbumDetails.length > 0 && findPostAlbumDetails(index).count} postType={"ALBUM"} />
              } else if (post.type === "AUDIO") {
                return <PostMusic key={index} music={post.audio} postComment={post.comments.length} postLike={post.nb_of_likers} postType={"AUDIO"} />
              }
            }
          )}
        {/*{Array.from({ length: 2 }, (_, index) => (*/}
        {/*  <PostImg key={index} postCover={Fond1} postComment={542} postLike={214} />*/}
        {/*))}*/}
        {/*{Array.from({ length: 3 }, (_, index) => (*/}
        {/*  <PostPlaylist key={index} postCover={Fond1} postComment={542} postLike={214} postCount={52} postType={"PLAYLIST"} />*/}
        {/*))}*/}
        {/*{Array.from({ length: 2 }, (_, index) => (*/}
        {/*  <PostMusic key={index} music={null} postComment={542} postLike={214} postType={"MUSIC"} />*/}
        {/*))}*/}
        {/*{Array.from({ length: 2 }, (_, index) => (*/}
        {/*  <PostImg  key={index}postCover={Fond1} postComment={542} postLike={214} />*/}
        {/*))}*/}
      </div>
    );
  }
}